<div class="page-container">
  <form [formGroup]="regForm" (ngSubmit)="register()" #formDirective="ngForm">
    <mat-card class="login-reg">
      <mat-card-header>
        <mat-card-title>إنشاء حساب جديد</mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <mat-divider style="margin-bottom: 1rem" />

        <mat-form-field>
          <mat-label>أدخل اسم المستخدم</mat-label>
          <mat-icon matPrefix>person</mat-icon>
          <input
            matInput
            placeholder="اسم المستخدم"
            [formControl]="name"
            required
          />
          <mat-error *ngIf="name.invalid">{{
            getErrorMessageName()
          }}</mat-error>
        </mat-form-field>

        <span
          nz-popover
          nzPopoverContent="
            يجب إدخال عنوان بريد إلكتروني صحيح، سيتم إرسال رابط تأكيد الحساب
            إليه
            "
          nzPopoverPlacement="rightTop"
          [nzPopoverVisible]="true"
          [nzPopoverTrigger]="null"
          [nzPopoverOverlayStyle]="{
            textAlign: 'right',
            width: '300px'
          }"
        >
          <mat-form-field style="height: 105px">
            <mat-label>أدخل عنوان البريد الإلكتروني</mat-label>
            <mat-icon matPrefix>email</mat-icon>
            <input
              matInput
              placeholder="example@example.com"
              [formControl]="email"
              required
            />
            <mat-error *ngIf="email.invalid">{{
              getErrorMessageEmail()
            }}</mat-error>
          </mat-form-field>
        </span>

        <span
          nz-popover
          nzPopoverContent="
            كلمة المرور يجب أن تحتوي على الأقل 8 أحرف، منها حرف كبير، حرف صغير، رقم، ورمز مميز
            "
          nzPopoverPlacement="rightTop"
          [nzPopoverVisible]="true"
          [nzPopoverTrigger]="null"
          [nzPopoverOverlayStyle]="{
            textAlign: 'right',
            width: '300px'
          }"
        >
          <mat-form-field>
            <mat-label>أدخل كلمة المرور</mat-label>
            <mat-icon matPrefix>lock</mat-icon>
            <input
              matInput
              required
              [formControl]="password"
              [type]="hide ? 'password' : 'text'"
            />
            <button
              mat-icon-button
              matSuffix
              (click)="hide = !hide"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide"
            >
              <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
            </button>
            <mat-error *ngIf="password.invalid">{{
              getErrorMessagePassword()
            }}</mat-error>
          </mat-form-field>
        </span>
      </mat-card-content>

      <mat-card-actions>
        <button mat-raised-button [disabled]="regForm.invalid">
          إنشاء حساب
        </button>
      </mat-card-actions>
      <div class="footer-action">
        لديك حساب؟
        <a routerLink="/login"> تسجيل الدخول</a>
      </div>
    </mat-card>
  </form>
</div>
