import { Component } from '@angular/core';
import { UserInfo } from 'src/app/interfaces/user';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-statistics',
  templateUrl: './user-statistics.component.html',
  styleUrls: ['./user-statistics.component.css'],
})
export class UserStatisticsComponent {
  user: UserInfo = this.userService.userInfo;

  constructor(private userService: UserService) {}
}
