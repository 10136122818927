<header class="header">
  <nav mat-tab-nav-bar [tabPanel]="tabPanel">
    <span class="nav-links">
      <a routerLink="/" class="logo">
        <img src="assets/logo.png" alt="Logo" />
      </a>

      <a mat-tab-link *ngFor="let page of pages" [routerLink]="page.path">
        {{ page.title }}
      </a>

      <div class="profile-link" *ngIf="isLoggedin()">
        <a id="profile" mat-tab-link routerLink="/myProfile">
          <img
            class="profile-img"
            [src]="userInfo.profilePicture"
            alt="profile picture"
          />
          أهلا {{ userInfo.name }}
        </a>
        <div style="display: flex; flex-direction: column; align-items: center">
          <span
            class="icon"
            nz-icon
            nzType="trophy"
            nzTheme="fill"
            style="color: #ffd965"
          ></span>
          <nz-statistic [nzValue]="(userInfo.points | number)!"></nz-statistic>
        </div>
      </div>
    </span>
  </nav>

  <button *ngIf="!isLoggedin()" mat-raised-button routerLink="/login">
    تسجيل الدخول
  </button>

  <button
    *ngIf="isLoggedin()"
    mat-raised-button
    routerLink="/"
    (click)="logout()"
  >
    تسجيل الخروج
  </button>
</header>

<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
