<h1 mat-dialog-title id="font" style="font-size: x-large">إضافة صديق</h1>

<div mat-dialog-content id="font">
  أدخل البريد الإلكتروني الخاص بالشخص الذي تريد إضافته
</div>

<div class="form-field">
  <mat-form-field>
    <mat-label>أدخل عنوان البريد الإلكتروني</mat-label>
    <mat-icon matPrefix>email</mat-icon>
    <input
      matInput
      placeholder="example@example.com"
      [formControl]="email"
      required
    />
    <mat-error *ngIf="email.invalid">{{ getErrorMessageEmail() }}</mat-error>
  </mat-form-field>
</div>

<div class="buttons" mat-dialog-actions>
  <button
    mat-raised-button
    [disabled]="email.invalid"
    mat-dialog-close
    (click)="addFriend()"
  >
    تأكيد
  </button>

  <button mat-raised-button mat-dialog-close>إغلاق</button>
</div>
