import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { LessonsService } from 'src/app/services/lessons.service';
import { LessonList, lessonData } from 'src/app/interfaces/lessons';

@Component({
  selector: 'app-lesson-page',
  templateUrl: './intro-learn-page.component.html',
  styleUrls: ['./intro-learn-page.component.css'],
})
export class IntroLearnPageComponent implements OnInit {
  steps = 1;
  hearts = [{ keep: true }, { keep: true }, { keep: true }];

  //progress bar
  percent = 0;

  constructor(private router: Router) {}

  ngOnInit() {}

  increaseProgressBar(): void {
    this.percent = this.percent > 100 ? 100 : this.percent + 14.5;
  }

  declineProgressBar(): void {
    this.percent = this.percent < 0 ? 0 : this.percent - 14.5;
  }

  //button action (move to question/check answer)
  isQuestion = false;
  isCorrect = false;
  showFeedback = false;

  next() {
    this.steps++;
    this.increaseProgressBar();
    this.action();
  }

  prev() {
    this.steps--;
    this.declineProgressBar();
    this.action();
  }

  action() {
    switch (this.steps) {
      case 1:
        // show learning veideo
        this.isQuestion = false;
        this.showFeedback = false;
        this.hearts = [{ keep: true }, { keep: true }, { keep: true }];

        break;
      case 2:
        // transition to question
        this.isQuestion = true;
        this.isCorrect = false;
        this.showFeedback = false;
        break;
      case 3:
        // show feedback - correct answer
        this.showFeedback = true;
        this.isCorrect = true;
        this.hearts = [{ keep: true }, { keep: true }, { keep: true }];
        break;
      case 4:
        // show feedback - incorrect answer
        this.isCorrect = false;
        this.hearts = [{ keep: false }, { keep: true }, { keep: true }];
        break;
      case 5:
        // lose lesson
        this.isCorrect = false;
        this.hearts = [{ keep: false }, { keep: false }, { keep: false }];
        break;
      case 6:
        // win lesson
        this.isCorrect = true;
        this.hearts = [{ keep: true }, { keep: true }, { keep: true }];
        break;
      case 7:
        // win lesson second trial
        this.isQuestion = true;
        this.showFeedback = true;
        break;
      case 8:
        // finish
        this.isQuestion = false;
        this.showFeedback = false;
        break;
    }
  }

  leaveLesson() {
    this.router.navigate(['/lessons-list']);
  }
}
