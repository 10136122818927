<div class="page-container">
  <div class="pageTitle">
    <h1>المتصدرون</h1>
  </div>

  <div nz-row [nzGutter]="48">
    <div
      nz-col
      [nzSpan]="8"
      *ngFor="let leaderboard of leaderboards | keyvalue; let i = index"
    >
      <div class="list-type">
        {{ titles.at(i) }}
      </div>

      <div
        class="rank-container"
        *ngFor="let leaderboradItem of leaderboard.value; let j = index"
      >
        <img
          class="rank-img"
          src="{{ ranks.at(j)?.imgSrc }}"
          alt="{{ ranks.at(j)?.rank }}"
        />
        <mat-card>
          <mat-card-header>
            <img
              class="avatar"
              mat-card-avatar
              src="{{ leaderboradItem.photo_url }}"
              alt=""
            />
            <mat-card-title>{{ leaderboradItem.name }}</mat-card-title>
            <mat-card-subtitle>
              {{ leaderboradItem.total_points }} XP
            </mat-card-subtitle>
          </mat-card-header>
        </mat-card>
      </div>

      <!-- leaderboard does not have users -->
      <ng-container *ngIf="leaderboard.value.length == 0 && !loading">
        <mat-card class="empty-list">
          <mat-card-content>
            لا يوجد مستخدمين في القائمة حاليا
          </mat-card-content>
        </mat-card>
      </ng-container>
    </div>

    <div
      class="loading"
      *ngIf="leaderboards.topUsersOfAllTime.length == 0 && loading"
    >
      <mat-spinner />
      <p>...الرجاء الانتظار حتى ينتهي تحميل البيانات</p>
    </div>
  </div>
</div>
