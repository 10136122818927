// verification.component.ts
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.css'],
})
export class VerificationComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private notification: NzNotificationService
  ) {}

  ngOnInit(): void {
    // extract verification token from URL
    const email = this.route.snapshot.queryParams['email'];
    const verificationToken = this.route.snapshot.queryParams['token'];

    // verify token using API
    this.apiService.verify(email, verificationToken).subscribe(
      (response: any) => {
        console.log('Verification success:', response);

        this.notification.create('success', 'نجاح', 'تم تأكيد الحساب بنجاح', {
          nzDuration: 3000,
        });

        // redirect user to login page
        this.router.navigate(['/login']);
      },
      (error) => {
        // console.error('Verification error:', error);

        this.notification.create('error', 'خطأ', 'عفوا، حدث خطأ غير متوقع', {
          nzDuration: 3000,
        });

        // redirect user to home page
        this.router.navigate(['/']);
      }
    );
  }
}
