import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-dictionary-page',
  templateUrl: './dictionary-page.component.html',
  styleUrls: ['./dictionary-page.component.css'],
})
export class DictionaryPageComponent implements OnInit {
  alphabetCards: any;
  numberstCards: any;
  wordsCards: any;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.http
      .get('assets/local-data/dictionary-data.json')
      .subscribe((data: any) => {
        this.alphabetCards = data.alphabet;
        this.numberstCards = data.numbers;
        this.wordsCards = data.words;
      });
  }
}
