<div class="page-container">
  <div class="pageTitle">
    <h1>صفحتي</h1>
  </div>

  <div nz-row [nzGutter]="[32, 32]" style="direction: rtl">
    <div nz-col nzSpan="12">
      <!-- Profile info -->
      <h1 class="title">معلومات المستخدم</h1>
      <app-user-info-card />
    </div>

    <div nz-col nzSpan="12">
      <!-- User statistics -->
      <h1 class="title">الإحصائيات</h1>
      <app-user-statistics />
    </div>

    <div nz-col nzSpan="12">
      <!-- Achievements -->
      <h1 class="title">الإنجازات</h1>
      <app-user-achievements />
    </div>

    <div nz-col nzSpan="12" class="friendsContainer">
      <!-- Friends list -->
      <h1 class="title">قائمة الأصدقاء</h1>
      <app-user-friends-list />
    </div>
  </div>
</div>
