import { Injectable } from '@angular/core';
import { Friend, UserInfo } from '../interfaces/user';
import { ApiService } from './api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userInfo: UserInfo = {
    id: '',
    name: '',
    email: '',
    points: 0,
    profilePicture:
      'https://i.pinimg.com/736x/2f/15/f2/2f15f2e8c688b3120d3d26467b06330c.jpg',
    joinDate: new Date(),
    friends: [],
    achievements: {
      star: { isAchieved: false, progress: 0, target: 1000 },
      hero: { isAchieved: false, progress: 0, target: 5 },
      consecutiveWins: { isAchieved: false, progress: 0, target: 5 },
      toughLessons: { isAchieved: false, progress: 0, target: 3 },
      popular: { isAchieved: false, progress: 0, target: 20 },
      warrior: { isAchieved: false, progress: 0, target: 1 },
    },
    stats: {
      numberOfWins: 0,
      numberOfFails: 0,
      totalPoints: 0,
    },
  };

  constructor(
    private apiService: ApiService,
    private notification: NzNotificationService
  ) {}

  // update user information
  updateUser(update: Partial<UserInfo>) {
    Object.assign(this.userInfo, update);
  }

  // fetches user profile information from the API and updates userInfo
  async updateProfileInfo() {
    try {
      const response = await this.apiService.getProfile().toPromise();

      // extract month and year from the date string
      const [month, year] = response.joinDate.split(' ');

      // parse the date without specifying the day
      const joinDate = new Date(`${month} 1, ${year}`);

      // download profile picture and wait for it to finish
      const profilePicture = await this.downloadProfilePicture(
        response.profilePicture
      );

      // update user info with profile data
      this.updateUser({
        id: response.userId,
        name: response.name,
        email: response.email,
        joinDate: joinDate,
        profilePicture:
          profilePicture ||
          'https://i.pinimg.com/736x/2f/15/f2/2f15f2e8c688b3120d3d26467b06330c.jpg', // fallback URL if profile picture is not available
      });
    } catch (error) {
      console.error('Get profile error: ', error);
    }
  }

  // fetches user points from the API and updates userInfo
  updatePoints() {
    this.apiService.getPoints(this.userInfo.id).subscribe(
      (response) => {
        // update user info with points data
        let points;
        if (response.points == null) {
          points = 0;
        } else {
          points = response.points;
        }

        this.updateUser({ points });
      },
      (error) => {
        console.error('Get points error: ', error.error.error);
      }
    );
  }

  // fetches user friends from the API and updates userInfo
  async updateFriends(userId: string) {
    try {
      const response = await this.apiService.getFriends(userId).toPromise();

      // download profile pictures for all friends
      const updatedFriends = await Promise.all(
        response.friends.map(async (friend: Friend) => {
          const profilePicture = await this.downloadProfilePicture(
            friend.photo_url
          );
          return { ...friend, photo_url: profilePicture };
        })
      );

      // update user info with friends data
      this.updateUser({ friends: updatedFriends });
    } catch (error) {
      console.error('Get friends error: ', error);
    }
  }

  // fetches user achievements from the API and updates userInfo
  updateAchievements() {
    this.apiService.getAchievements().subscribe(
      (response) => {
        // update user info with achievements data
        this.updateUser({ achievements: response });
      },
      (error) => {
        console.error('Get achievements error: ', error.error.error);
      }
    );
  }

  // fetches user stats from the API and updates userInfo
  updateStats() {
    this.apiService.getStat().subscribe(
      (response) => {
        // update user info with stats data
        let totalPoints;
        if (response.totalPoints == null) {
          totalPoints = 0;
        } else {
          totalPoints = response.totalPoints;
        }

        let stats = response;
        stats.totalPoints = totalPoints;

        this.updateUser({ stats });
      },
      (error) => {
        console.error('Get stats error: ', error.error.error);
      }
    );
  }

  // fetches all user information when the user logs in
  fetchUserInfo(userId: string, email: string) {
    // update user id and email after login
    this.updateUser({ id: userId, email });

    // update user profile info
    this.updateProfileInfo();

    // update user points
    this.updatePoints();

    // update user friends
    this.updateFriends(userId);

    // update user achievements
    this.updateAchievements();

    // update user stats
    this.updateStats();
  }

  // update user info when reload
  fetchAfterReload() {
    // get userId and email
    const userId = localStorage.getItem('id');
    const email = localStorage.getItem('email');

    // update all user info
    this.fetchUserInfo(userId!, email!);
  }

  // update after submitting a lesson
  updateUserInfoAfterSubmit() {
    // update user points
    this.updatePoints();

    // update user achievements
    this.updateAchievements();

    // update user stats
    this.updateStats();
  }

  // update user name
  updateName(newName: string) {
    this.apiService.updateName(newName).subscribe(
      (response) => {
        // update user info with profile data
        this.updateUser({ name: newName });

        this.notification.create('success', 'نجاح', 'تم تحديث الاسم بنجاح', {
          nzDuration: 3000,
        });
      },
      (error) => {
        console.error('Update name error: ', error.error.error);

        if (
          error.error.error == 'New name must be at most 15 characters long'
        ) {
          this.notification.create(
            'error',
            'خطأ',
            '!يجب ألا يتعدى الاسم 15 حرف',
            {
              nzDuration: 3000,
            }
          );
        } else {
          this.notification.create('error', 'خطأ', 'عفوا، حدث خطأ غير متوقع', {
            nzDuration: 3000,
          });
        }
      }
    );
  }

  // update user profile picture
  updateProfilePicture(formData: FormData) {
    this.apiService.uploadProfilePicture(formData).subscribe(
      (response) => {
        // update user profile picture
        this.updateProfileInfo();

        this.notification.create(
          'success',
          'نجاح',
          'تم تحديث صورة العرض بنجاح',
          {
            nzDuration: 3000,
          }
        );
      },
      (error) => {
        console.error('Upload profile picture error: ', error.error.error);

        this.notification.create('error', 'خطأ', 'عفوا، حدث خطأ غير متوقع', {
          nzDuration: 3000,
        });
      }
    );
  }

  // download user profile picture
  downloadProfilePicture(userProfilePic: string): Promise<string> {
    return new Promise<string>((resolve) => {
      this.apiService.downloadProfilePicture(userProfilePic).subscribe(
        (response: any) => {
          const imageUrl = URL.createObjectURL(response);
          resolve(imageUrl);
        },
        () => {
          console.error(
            'Download profile picture error: error downloading picture',
            userProfilePic
          );

          // return default profile picture URL
          resolve(
            'https://i.pinimg.com/736x/2f/15/f2/2f15f2e8c688b3120d3d26467b06330c.jpg'
          );
        }
      );
    });
  }

  // add friend
  addFriend(email: string) {
    this.apiService.addFriend(this.userInfo.id, email).subscribe(
      (response) => {
        // update user info with friends data
        this.updateFriends(this.userInfo.id);

        this.notification.create(
          'success',
          'نجاح',
          'تم إضافة صديق جديد بنجاح',
          {
            nzDuration: 3000,
          }
        );
      },
      (error) => {
        console.error('Add friend error: ', error.error.error);

        if (error.error.error == 'User not found') {
          this.notification.create(
            'error',
            'خطأ',
            '!لا يمكن إيجاد مستخدم بعنوان البريد الإلكتروني هذا',
            {
              nzDuration: 3000,
            }
          );
        } else if (error.error.error == 'User is already in your friend list') {
          this.notification.create(
            'error',
            'خطأ',
            '!هذا المستخدم صديقك بالفعل',
            {
              nzDuration: 3000,
            }
          );
        } else {
          this.notification.create('error', 'خطأ', 'عفوا، حدث خطأ غير متوقع', {
            nzDuration: 3000,
          });
        }
      }
    );
  }

  // delete friend
  deleteFriend(friendId: string) {
    this.apiService.deleteFriend(this.userInfo.id, friendId).subscribe(
      (response) => {
        // update user info with friends data
        this.updateFriends(this.userInfo.id);

        this.notification.create('success', 'نجاح', 'تم حذف الصديق بنجاح', {
          nzDuration: 3000,
        });
      },
      (error) => {
        console.error('Delete friend error: ', error.error.error);

        if (error.error.error == 'Friend not found in your list') {
          this.notification.create(
            'error',
            'خطأ',
            '!لا يمكن إيجاد هذا الصديق في قائمة أصدقائك',
            {
              nzDuration: 3000,
            }
          );
        } else {
          this.notification.create('error', 'خطأ', 'عفوا، حدث خطأ غير متوقع', {
            nzDuration: 3000,
          });
        }
      }
    );
  }
}
