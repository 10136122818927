import { Component } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { LessonsService } from 'src/app/services/lessons.service';

@Component({
  selector: 'app-lessons-page',
  templateUrl: './lessons-page.component.html',
  styleUrls: ['./lessons-page.component.css'],
  animations: [
    trigger('listAnimation', [
      transition(':increment', [
        style({ transform: 'translateX(-100%)' }),
        animate('0.5s ease-out', style({ transform: 'none' })),
      ]),
    ]),
  ],
})
export class LessonsPageComponent {
  selectedLessonType = 0;
  changeCounter = 0;

  constructor(private lessonService: LessonsService) {}

  ngOnInit(): void {
    this.lessonService.getLessons();
  }

  selectLessonType(index: number) {
    if (this.selectedLessonType !== index) {
      this.selectedLessonType = index;
      this.changeCounter++;
    }
  }

  get tiles() {
    return this.selectedLessonType === 0
      ? this.lessonService.lessonList.alphabet
      : this.lessonService.lessonList.numbers;
  }

  get title() {
    return this.titles[this.selectedLessonType];
  }

  titles: string[] = ['الحروف الأبجدية', 'الأرقام'];
}
