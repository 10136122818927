import { Component, Input } from '@angular/core';
import { lessonData } from 'src/app/interfaces/lessons';

interface Tile {
  id?: string;
  order: number;
  category?: string;
  name: string;
  description?: string;
  cols: number;
  rows: number;
  available?: boolean;
  result?: boolean;
  lessonSide?: boolean;
}

@Component({
  selector: 'app-lessons-list',
  templateUrl: './lessons-list.component.html',
  styleUrls: ['./lessons-list.component.css'],
})
export class LessonsListComponent {
  tiles: Tile[] = [];
  description: string = '';

  @Input({ required: true })
  lessonsData: lessonData[] = [];

  @Input({ required: true })
  title: string = '';

  isEmpty: boolean = false;

  ngOnChanges(): void {
    this.isEmpty = this.lessonsData == null;

    this.generateTiles();
  }

  generateTiles(): void {
    if (!this.isEmpty) {
      this.description =
        'يحتوي هذا الدرس على مقدمة تعريفية عن نظامنا وعملية التعلم';

      // start pattern
      this.tiles = [
        { order: -1, name: 'filler', cols: 3, rows: 1 },
        {
          id: 'start',
          order: 0,
          category: 'start',
          name: 'start',
          description: this.description,
          cols: 1,
          rows: 2,
          available: true,
          result: true,
        },
        { order: -1, name: 'filler', cols: 1, rows: 1 },
      ];

      let rockCounter: number;
      let lessonSide = true; // true for left, false for right
      let lastPassedLessonOrder = 0;

      // generate lessons and rocks
      this.lessonsData.forEach((lesson) => {
        this.description = lesson.description;

        rockCounter = Math.floor(Math.random() * 4) + 1;

        const rockName = `rock${rockCounter}`;

        const lessonName =
          lesson.type === 'standard' ? `level${lesson.order}` : 'challenge';

        const available = this.isLessonAvailable(lesson, lastPassedLessonOrder);

        if (lessonSide) {
          this.tiles.push(
            { order: -1, name: `rtl-${rockName}`, cols: 2, rows: 2 },
            {
              id: lesson.id,
              order: lesson.order,
              category: lesson.category,
              name: lessonName,
              description: this.description,
              cols: 1,
              rows: 2,
              available,
              result: lesson.result,
              lessonSide,
            },
            { order: -1, name: 'filler', cols: 1, rows: 2 }
          );
        } else {
          this.tiles.push(
            { order: -1, name: `ltr-${rockName}`, cols: 2, rows: 2 },
            { order: -1, name: 'filler', cols: 1, rows: 2 },
            {
              id: lesson.id,
              order: lesson.order,
              category: lesson.category,
              name: lessonName,
              description: this.description,
              cols: 1,
              rows: 2,
              available,
              result: lesson.result,
              lessonSide,
            }
          );
        }
        rockCounter++;

        // toggle side
        lessonSide = !lessonSide;

        // update last passed lesson order if this lesson was passed
        if (lesson.result) {
          lastPassedLessonOrder = lesson.order;
        }
      });
    } else {
      this.tiles = [];
    }
  }

  isLessonAvailable(
    lesson: lessonData,
    lastPassedLessonOrder: number
  ): boolean {
    // if it is a revision, all prev lessons are available, the lesson after is available too
    if (lesson.type === 'revision' && lesson.result) {
      for (let index = lesson.order - 0.5; index > 0; index--) {
        const tile = this.tiles.find((tile) => tile.order === index);
        if (tile) {
          tile.available = true;
        }
      }

      return true;
    }

    // if it is a revision, it is available
    if (lesson.type === 'revision') {
      return true;
    }

    // first lesson or passed lessons are always available
    if (lesson.order === 1 || lesson.result) return true;

    // next lesson after the last passed one is available
    if (
      lesson.order === lastPassedLessonOrder + 1 ||
      lesson.order === lastPassedLessonOrder + 0.5
    )
      return true;

    // otherwise, the lesson is not available
    return false;
  }

  getImagePath(lessonName: string): string {
    return `/assets/images/lessons-map-imgs/${lessonName}.png`;
  }

  getPagePath(lessonCategory: string, lessonID: string): string {
    let lessonPath;
    if (lessonCategory == 'start') {
      lessonPath = lessonCategory;
    } else {
      lessonPath = lessonCategory + '/' + lessonID;
    }

    return `/lesson/${lessonPath}`;
  }

  isLesson(tile: Tile): boolean {
    return tile.order != -1;
  }

  notFiller(tile: Tile): boolean {
    return tile.order == -1 && tile.name != 'Filler';
  }
}
