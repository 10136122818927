import { Component } from '@angular/core';
import { LeaderboardItem, Leaderboards } from 'src/app/interfaces/leaderboard';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-leaderboard-page',
  templateUrl: './leaderboard-page.component.html',
  styleUrls: ['./leaderboard-page.component.css'],
})
export class LeaderboardPageComponent {
  leaderboards: Leaderboards = {
    topUsersToday: [],
    topUsersThisMonth: [],
    topUsersOfAllTime: [],
  };

  loading = true;

  titles = ['أفضل 5 في إيماء', 'أفضل 5 لهذا الشهر', 'أفضل 5 اليوم'];
  ranks = [
    {
      rank: 'المرتبة الأولى',
      imgSrc: 'assets/images/leaderboard-icons/First-place.png',
    },
    {
      rank: 'المرتبة الثانية',
      imgSrc: 'assets/images/leaderboard-icons/Second-place.png',
    },
    {
      rank: 'المرتبة الثالثة',
      imgSrc: 'assets/images/leaderboard-icons/Third-place.png',
    },
    {
      rank: 'المرتبة الرابعة',
      imgSrc: 'assets/images/leaderboard-icons/Forth-place.png',
    },
    {
      rank: 'المرتبة الخامسة',
      imgSrc: 'assets/images/leaderboard-icons/Fifth-place.png',
    },
  ];

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    this.getLeaderboard();
  }

  getLeaderboard() {
    this.apiService.getLeaderboard().subscribe(
      async (leaderboards) => {
        // Download profile pictures for all users in the leaderboard
        await this.downloadProfilePictures(leaderboards.topUsersToday);
        await this.downloadProfilePictures(leaderboards.topUsersThisMonth);
        await this.downloadProfilePictures(leaderboards.topUsersOfAllTime);

        this.leaderboards.topUsersToday = leaderboards.topUsersToday.slice(
          0,
          5
        );
        this.leaderboards.topUsersThisMonth =
          leaderboards.topUsersThisMonth.slice(0, 5);
        this.leaderboards.topUsersOfAllTime =
          leaderboards.topUsersOfAllTime.slice(0, 5);

        this.loading = false;
      },
      (error) => {
        console.log('Get leaderboard error: ', error);
        this.loading = false;
      }
    );
  }

  async downloadProfilePictures(users: LeaderboardItem[]) {
    for (let user of users) {
      try {
        const imageUrl = await this.userService.downloadProfilePicture(
          user.photo_url
        );
        user.photo_url = imageUrl;
      } catch (error) {
        console.error(
          'Error downloading profile picture for user: ',
          user.email
        );
        // Set default profile picture URL
        user.photo_url =
          'https://i.pinimg.com/736x/2f/15/f2/2f15f2e8c688b3120d3d26467b06330c.jpg';
      }
    }
  }
}
