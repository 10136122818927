import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { WebSocketService } from './web-socket.service';
import { EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-user-camera',
  templateUrl: './user-camera.component.html',
  styleUrls: ['./user-camera.component.css'],
})
export class UserCameraComponent implements OnDestroy {
  @ViewChild('videoElement') videoElement?: ElementRef<HTMLVideoElement>;
  @ViewChild('canvasElement') canvasElement?: ElementRef<HTMLCanvasElement>;

  @Input({ required: true })
  lessonCategory: string = '';

  // output event emitter to send the class back to the lesson component
  @Output() receivedResponse = new EventEmitter();

  cameraPaused: boolean = false;
  stream?: MediaStream;

  constructor(private webSocketService: WebSocketService) {}

  ngOnInit(): void {
    this.startRecognition();
  }

  ngOnDestroy(): void {
    this.close();
  }

  startRecognition(): void {
    navigator.mediaDevices
      .getUserMedia({ video: { width: 480, height: 270 } }) // Set width and height here
      .then((stream) => {
        this.stream = stream;
        const video = this.videoElement!.nativeElement;
        video.srcObject = stream;
        video.play();
        this.setupWebSocket();
      })
      .catch((err) => {
        console.error('Error accessing camera:', err);
      });
  }

  setupWebSocket(): void {
    // Define a variable to hold the interval ID
    let intervalId: any;

    const port = this.lessonCategory == 'alphabet' ? 8000 : 8100;

    this.webSocketService.connect(
      `wss://emaa.work.gd/arsl-recognition_${this.lessonCategory}`
    );

    this.webSocketService.socket!.onopen = () => {
      console.log('WebSocket Connected');
      intervalId = setInterval(() => {
        if (!this.cameraPaused) {
          this.captureAndSendImage(
            this.canvasElement!.nativeElement,
            this.videoElement!.nativeElement,
            this.webSocketService.socket!
          );
        }
      }, 1000); // Interval as required
    };

    this.webSocketService.socket!.onmessage = (event) => {
      const response = JSON.parse(event.data);

      // emit the classReceived event with the extracted class
      this.receivedResponse.emit(response);
    };

    this.webSocketService.socket!.onclose = () => {
      console.log('WebSocket Disconnected');
      // Clear the interval when the WebSocket is disconnected
      clearInterval(intervalId);
    };
  }

  captureAndSendImage(
    canvas: HTMLCanvasElement,
    video: HTMLVideoElement,
    socket: WebSocket
  ): void {
    const ctx = canvas.getContext('2d');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    ctx!.drawImage(video, 0, 0, canvas.width, canvas.height);
    canvas.toBlob((blob) => {
      if (blob) {
        socket.send(blob);
      }
    }, 'image/jpeg');
  }

  toggleCamera(): void {
    if (this.cameraPaused) {
      if (this.stream) {
        this.stream.getTracks().forEach((track) => {
          track.enabled = true; // Enable the camera tracks
        });
      }
      this.cameraPaused = false;
    } else {
      if (this.stream) {
        this.stream.getTracks().forEach((track) => {
          track.enabled = false; // Disable the camera tracks
        });
      }
      this.cameraPaused = true;
    }
  }

  close(): void {
    if (this.stream) {
      this.stream.getTracks().forEach((track) => {
        track.enabled = false;
        track.stop(); // Stop the camera tracks
      });
    }
    if (this.webSocketService.socket) {
      this.webSocketService.socket.close(); // Close WebSocket connection
      console.log('WebSocket Closed');
    }
  }
}
