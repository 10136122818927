<div class="page-container">
  <div class="pageTitle">
    <h1>تواصل معنا</h1>
  </div>

  <div class="greenContainer">
    <p>:نستقبل اقتراحاتكم وأسئلتكم عن إيماء عبر الإيميل التالي</p>
    <p>
      <a class="link" href="mailto:emaa.arsl@gmail.com">emaa.arsl@gmail.com</a>
    </p>
  </div>
</div>
