import { Component } from '@angular/core';
import {
  FormBuilder,
  Validators,
  AbstractControl,
  ValidatorFn,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.css'],
})
export class RegisterPageComponent {
  hide = true;

  regForm = this.fb.group({
    name: ['', [Validators.required, Validators.maxLength(15)]],
    email: [
      '',
      [Validators.required, Validators.email, this.customEmailValidator()],
    ],
    password: [
      '',
      [
        Validators.required,
        Validators.pattern(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/
        ),
      ],
    ],
  });

  customEmailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const email = control.value as string;
      if (!email) {
        return null; // Return null if email is empty (let required validator handle this)
      }
      if (!email.endsWith('.com')) {
        return { invalidEmail: true }; // Return a validation error if email does not end with '.com'
      }
      return null; // Return null if email is valid
    };
  }

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private router: Router,
    private notification: NzNotificationService
  ) {}

  get name() {
    return this.regForm.controls['name'];
  }
  get email() {
    return this.regForm.controls['email'];
  }
  get password() {
    return this.regForm.controls['password'];
  }

  getErrorMessageEmail() {
    if (this.email.hasError('required')) {
      return this.getErrorMessage();
    } else if (this.email.hasError('email')) {
      return 'عنوان البريد الإلكتروني المدخل غير صحيح';
    } else return 'يجب إدخال عنوان بريد إلكتروني صحيح';
  }

  getErrorMessagePassword() {
    if (this.password.hasError('required')) {
      return this.getErrorMessage();
    } else if (this.password.hasError('pattern')) {
      return 'يجب أن تحتوي كلمة المرور على كل الشروط المطلوبة';
    } else return 'يجب إدخال كلمة مرور صحيحة';
  }

  getErrorMessageName() {
    if (this.name.hasError('required')) {
      return this.getErrorMessage();
    } else if (this.name.hasError('maxlength')) {
      return '!يجب ألا يتعدى الاسم 15 حرف';
    } else return 'يجب إدخال اسم صحيح';
  }

  getErrorMessage() {
    return 'يجب تعبئة هذه الخانة';
  }

  register() {
    const name = this.name.value!;
    const email = this.email.value!;
    const password = this.password.value!;

    this.apiService
      .register(name as string, email as string, password as string)
      .subscribe(
        (response) => {
          this.notification.create('success', 'نجاح', 'تم إنشاء الحساب بنجاح', {
            nzDuration: 3000,
          });

          // Redirect to login page
          this.router.navigate(['/login']);
        },
        (error) => {
          if (error.error.error === 'Email already exists') {
            this.notification.create(
              'error',
              'خطأ',
              'عنوان البريد الإلكتروني موجود من قبل، يجب التسجيل بعنوان جديد أو تسجيل الدخول بهذا العنوان',
              {
                nzDuration: 3000,
              }
            );
          } else {
            this.notification.create(
              'error',
              'خطأ',
              'عفوا، حدث خطأ غير متوقع',
              {
                nzDuration: 3000,
              }
            );
          }
        }
      );
  }
}
