<app-header />

<div class="content" *ngIf="isAppPage">
  <router-outlet></router-outlet>
</div>

<div class="content greenContainer" *ngIf="!isAppPage">
  <h1>تعلم لغة الإشارة العربية مع إيماء</h1>
  <button mat-raised-button (click)="navigateToLessonPage()">إبدأ الآن</button>
  <p>بوابتك إلى عالم من التواصل التعبيري حيث كل إيماءة تحكي قصة</p>
</div>

<app-footer />
