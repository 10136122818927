import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { ar_EG } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import ar from '@angular/common/locales/ar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { HttpClientModule } from '@angular/common/http';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatGridListModule } from '@angular/material/grid-list';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzImageModule } from 'ng-zorro-antd/image';
import { MatListModule } from '@angular/material/list';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LessonsPageComponent } from './components/pages/lessons-page/lessons-page.component';
import { DictionaryPageComponent } from './components/pages/dictionary-page/dictionary-page.component';
import { LeaderboardPageComponent } from './components/pages/leaderboard-page/leaderboard-page.component';
import { AboutusPageComponent } from './components/pages/aboutus-page/aboutus-page.component';
import { LessonsListComponent } from './components/pages/lessons-page/lessons-list/lessons-list.component';
import { LearnPageComponent } from './components/pages/learn-page/learn-page.component';
import { LoginPageComponent } from './components/pages/login-page/login-page.component';
import { RegisterPageComponent } from './components/pages/register-page/register-page.component';
import { DictionaryCardComponent } from './components/pages/dictionary-page/dictionary-card/dictionary-card.component';
import { ProfilePageComponent } from './components/pages/profile-page/profile-page.component';
import { UserInfoCardComponent } from './components/pages/profile-page/user-info-card/user-info-card.component';
import { UserStatisticsComponent } from './components/pages/profile-page/user-statistics/user-statistics.component';
import { UserAchievementsComponent } from './components/pages/profile-page/user-achievements/user-achievements.component';
import { UserFriendsListComponent } from './components/pages/profile-page/user-friends-list/user-friends-list.component';
import { AddFriendDialogComponent } from './components/pages/profile-page/user-friends-list/dialogs/add-friend-dialog/add-friend-dialog.component';
import { IntroLearnPageComponent } from './components/pages/intro-learn-page/intro-learn-page.component';
import { UserCameraComponent } from './components/pages/learn-page/user-camera/user-camera.component';
import { VerificationComponent } from './components/verification/verification.component';

registerLocaleData(ar);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LessonsPageComponent,
    DictionaryPageComponent,
    LeaderboardPageComponent,
    AboutusPageComponent,
    LessonsListComponent,
    LearnPageComponent,
    LoginPageComponent,
    RegisterPageComponent,
    DictionaryCardComponent,
    ProfilePageComponent,
    UserInfoCardComponent,
    UserStatisticsComponent,
    UserAchievementsComponent,
    UserFriendsListComponent,
    AddFriendDialogComponent,
    IntroLearnPageComponent,
    UserCameraComponent,
    VerificationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatSidenavModule,
    MatGridListModule,
    NzNotificationModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatDividerModule,
    NzCardModule,
    NzImageModule,
    MatListModule,
    NzListModule,
    NzGridModule,
    NzUploadModule,
    NzModalModule,
    NzStatisticModule,
    NzToolTipModule,
    MatDialogModule,
    NzIconModule,
    NzProgressModule,
    NzPopconfirmModule,
    NzPopoverModule,
    MatProgressSpinnerModule,
  ],
  providers: [{ provide: NZ_I18N, useValue: ar_EG }],
  bootstrap: [AppComponent],
})
export class AppModule {}
