import { Component } from '@angular/core';

import { FormControl, Validators } from '@angular/forms';
import { Observable, Observer } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { UserInfo } from 'src/app/interfaces/user';

import { NzUploadChangeParam, NzUploadFile } from 'ng-zorro-antd/upload';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-user-info-card',
  templateUrl: './user-info-card.component.html',
  styleUrls: ['./user-info-card.component.css'],
})
export class UserInfoCardComponent {
  user: UserInfo = this.userService.userInfo;
  text: any;

  constructor(
    private notification: NzNotificationService,
    private userService: UserService
  ) {}

  // update user profile img
  beforeUpload = (
    file: NzUploadFile,
    _fileList: NzUploadFile[]
  ): Observable<boolean> =>
    new Observable((observer: Observer<boolean>) => {
      // validate file type (image only)
      const isImg =
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg' ||
        file.type === 'image/png';
      if (!isImg) {
        this.notification.create(
          'error',
          'خطأ',
          'يرجى اختيار صورة من نوع (jpeg / jpg / png) لرفعها',
          {
            nzDuration: 3000,
          }
        );
        observer.complete();
        return;
      }

      // validate file size
      const isLt2M = file.size! / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.notification.create(
          'error',
          'خطأ',
          'يجب أن يكون حجم الصورة اصغر من 2 ميغابايت ',
          {
            nzDuration: 3000,
          }
        );
        observer.complete();
        return;
      }

      observer.next(isImg && isLt2M);
      observer.complete();
    });

  // handle update profile picture
  handleFileSelection = (event: NzUploadChangeParam) => {
    if (event.type === 'start') {
      this.updateProfilePicture(event.file.originFileObj!);
    }
  };

  updateProfilePicture(file: File): void {
    const formData = new FormData();
    formData.append('profilePicture', file as Blob);

    // perform the HTTP request to upload the file
    this.userService.updateProfilePicture(formData);
  }
  // }

  // update user name
  changeName = false;
  name = new FormControl('', [Validators.required, Validators.maxLength(15)]);

  // handle update name
  handleOk(): void {
    if (this.name.valid) {
      this.userService.updateName(this.name.value!);

      this.changeName = false;
      this.name.reset('');
    }
  }

  // handle cancel update name
  handleCancel(): void {
    this.changeName = false;
    this.name.reset('');
  }

  getErrorMessage() {
    if (this.name.hasError('required')) {
      return 'يجب تعبئة هذه الخانة';
    }
    if (this.name.hasError('maxlength')) {
      return 'يجب ألا يتعدى الاسم 15 حرف!';
    }
    return 'الرجاء إدخال اسم صحيح';
  }
}
