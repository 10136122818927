<div class="container">
  <div class="position-relative">
    <video #videoElement width="480" height="270"></video>
    <canvas
      #canvasElement
      class="position-absolute top-0 start-0"
      style="display: none"
    ></canvas>
  </div>
  <span class="button-holder">
    <button mat-raised-button (click)="toggleCamera()">
      <mat-icon>
        {{ cameraPaused ? "videocam_off" : "videocam" }}
      </mat-icon>
    </button>
  </span>
</div>
