import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LessonsPageComponent } from './components/pages/lessons-page/lessons-page.component';
import { DictionaryPageComponent } from './components/pages/dictionary-page/dictionary-page.component';
import { LeaderboardPageComponent } from './components/pages/leaderboard-page/leaderboard-page.component';
import { AboutusPageComponent } from './components/pages/aboutus-page/aboutus-page.component';
import { AppComponent } from './app.component';
import { LearnPageComponent } from './components/pages/learn-page/learn-page.component';
import { LoginPageComponent } from './components/pages/login-page/login-page.component';
import { RegisterPageComponent } from './components/pages/register-page/register-page.component';
import { authGuard } from './guards/auth.guard';
import { ProfilePageComponent } from './components/pages/profile-page/profile-page.component';
import { IntroLearnPageComponent } from './components/pages/intro-learn-page/intro-learn-page.component';
import { VerificationComponent } from './components/verification/verification.component';

const routes: Routes = [
  {
    path: 'homePage',
    title: 'إيماء',
    component: AppComponent,
  },
  {
    path: '',
    redirectTo: 'homePage',
    pathMatch: 'full',
  },
  {
    path: 'lessons-list',
    title: 'الدروس',
    component: LessonsPageComponent,
    canActivate: [authGuard],
  },
  {
    path: 'dictionary',
    title: 'المعجم',
    component: DictionaryPageComponent,
  },
  {
    path: 'leaderboard',
    title: 'المتصدرون',
    component: LeaderboardPageComponent,
    canActivate: [authGuard],
  },
  {
    path: 'aboutus',
    title: 'تواصل معنا',
    component: AboutusPageComponent,
  },
  {
    path: 'lesson/start',
    title: 'مقدمة',
    component: IntroLearnPageComponent,
    canActivate: [authGuard],
  },
  {
    path: 'lesson/:category/:id',
    component: LearnPageComponent,
    canActivate: [authGuard],
  },
  {
    path: 'myProfile',
    title: 'الصفحة الشخصية',
    component: ProfilePageComponent,
    canActivate: [authGuard],
  },
  {
    path: 'login',
    title: 'تسجيل الدخول',
    component: LoginPageComponent,
  },
  {
    path: 'register',
    title: 'إنشاء حساب جديد',
    component: RegisterPageComponent,
  },
  { path: 'verify', component: VerificationComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
