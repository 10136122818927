import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddFriendDialogComponent } from './dialogs/add-friend-dialog/add-friend-dialog.component';
import { UserInfo } from 'src/app/interfaces/user';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-friends-list',
  templateUrl: './user-friends-list.component.html',
  styleUrls: ['./user-friends-list.component.css'],
})
export class UserFriendsListComponent {
  user: UserInfo = this.userService.userInfo;

  constructor(public dialog: MatDialog, private userService: UserService) {}

  updateFriends() {
    this.user = this.userService.userInfo;
  }

  addFriend() {
    // display dialog to add friend
    const dialogRef = this.dialog.open(AddFriendDialogComponent);

    // subscribe to the dialog result
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'success') {
        // update friends list if friend added successfully
        this.updateFriends();
      }
    });
  }

  deleteFriend(userId: string) {
    // delete friend by id
    this.userService.deleteFriend(userId);

    // update friends list
    this.updateFriends();
  }

  noFriends() {
    return this.user.friends.length == 0;
  }
}
