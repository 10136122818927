<button class="button addFriendButton" mat-raised-button (click)="addFriend()">
  إضافة صديق
  <mat-icon style="order: 1">person_add</mat-icon>
</button>
<span class="friends-cards-container">
  <span class="firends-list" *ngFor="let friend of user.friends; let i = index">
    <div class="firend-order">{{ i + 1 }}</div>
    <mat-card>
      <mat-card-header>
        <img
          class="avatar"
          mat-card-avatar
          src="{{ friend.photo_url }}"
          alt="profile picure"
        />
        <mat-card-title>{{ friend.name }}</mat-card-title
        ><mat-card-subtitle> {{ friend.points }} XP </mat-card-subtitle>
      </mat-card-header>
      <button
        class="button deleteFriendButton"
        mat-raised-button
        nz-popconfirm
        nzPopconfirmTitle="هل أنت متأكد من حذف الصديق؟"
        nzPopconfirmPlacement="bottom"
        (nzOnConfirm)="deleteFriend(friend.friend_id)"
      >
        حذف
        <mat-icon style="order: 1">person_remove</mat-icon>
      </button>
    </mat-card>
  </span>

  <mat-card *ngIf="noFriends()">
    <mat-card-content>لا يوجد أصدقاء بعد</mat-card-content>
  </mat-card>
</span>
