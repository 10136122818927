<span class="achievements-cards-container">
  <mat-card
    *ngFor="let card of user.achievements | keyvalue; let i = index"
    [ngClass]="{ disabledCard: !card.value.isAchieved }"
  >
    <mat-icon
      nz-tooltip
      nzTooltipPlacement="top"
      [nzTooltipTitle]="getAchievementInfo(card.key)?.description"
      [nzTooltipMouseEnterDelay]="0.5"
    >
      info_outline
    </mat-icon>

    <img
      [alt]="getAchievementInfo(card.key)?.name"
      [src]="getImgSrc(getAchievementInfo(card.key)?.imgSrc!)"
    />

    <mat-card-header>
      <mat-card-title>
        {{ getAchievementInfo(card.key)?.name }}
      </mat-card-title>
    </mat-card-header>

    <mat-card-footer>
      <mat-card-subtitle>
        {{ card.value.progress }} من {{ card.value.target }}
      </mat-card-subtitle>
    </mat-card-footer>
  </mat-card>
</span>
