<mat-card>
  <mat-card-header>
    <nz-upload
      nzListType="picture-card"
      nzAccept="image/*"
      [nzBeforeUpload]="beforeUpload"
      (nzChange)="handleFileSelection($event)"
    >
      <mat-icon id="uploadIcon">upload</mat-icon>
    </nz-upload>
    <img
      class="avatar"
      mat-card-avatar
      [src]="user.profilePicture"
      alt="profile picture"
    />
    <!-- Display user name -->
    <mat-card-title *ngIf="!changeName" id="display-user-name">
      {{ user.name }}
      <button class="button" mat-raised-button (click)="changeName = true">
        تغيير الاسم
      </button>
    </mat-card-title>

    <!-- Update user name -->
    <mat-card-title *ngIf="changeName" id="update-user-name">
      <mat-form-field>
        <mat-label>أدخل اسم المستخدم الجديد</mat-label>
        <input
          matInput
          placeholder="اسم المستخدم الجديد"
          [formControl]="name"
          required
        />
        <mat-error *ngIf="name.invalid">
          {{ getErrorMessage() }}
        </mat-error>
      </mat-form-field>
      <span class="button">
        <button
          mat-raised-button
          [disabled]="name.invalid"
          (click)="handleOk()"
        >
          تأكيد
        </button>
        <br />
        <button mat-raised-button (click)="handleCancel()">إلغاء</button>
      </span>
    </mat-card-title>

    <mat-card-subtitle>
      <mat-icon>today</mat-icon>
      انضم إلى إيماء في تاريخ
      {{ user.joinDate | date : "mediumDate" }}
      <br />
      <mat-icon>people</mat-icon>
      {{ user.friends.length }}
      أصدقاء
    </mat-card-subtitle>
  </mat-card-header>
</mat-card>
