<div class="page-container" id="dictionary-page">
  <div class="pageTitle">
    <h1>المعجم</h1>
  </div>

  <mat-tab-group dynamicHeight mat-stretch-tabs="false" [dir]="'rtl'">
    <mat-tab label="الحروف الأبجدية">
      <app-dictionary-card
        *ngIf="alphabetCards"
        [rawDictionaryCards]="alphabetCards"
        type="alphabet"
      />
    </mat-tab>
    <mat-tab label="الأرقام">
      <app-dictionary-card
        *ngIf="numberstCards"
        [rawDictionaryCards]="numberstCards"
        type="numbers"
      />
    </mat-tab>
    <mat-tab label="الكلمات">
      <app-dictionary-card
        *ngIf="wordsCards"
        [rawDictionaryCards]="wordsCards"
        type="words"
      />
    </mat-tab>
  </mat-tab-group>

  <div class="loading" *ngIf="!alphabetCards || !numberstCards || !wordsCards">
    <mat-spinner />
    <p>...الرجاء الانتظار حتى ينتهي تحميل البيانات</p>
  </div>
</div>
