<nz-row [nzGutter]="16">
  <nz-col [nzSpan]="8">
    <nz-statistic
      [nzValue]="(user.stats['totalPoints'] | number)!"
      nzTitle="عدد النقاط المجمعة"
    />
    <span
      class="icon"
      nz-icon
      nzType="trophy"
      nzTheme="fill"
      style="color: #ffd965"
    ></span>
  </nz-col>
  <nz-col [nzSpan]="8">
    <nz-statistic
      [nzValue]="(user.stats['numberOfWins'] | number)!"
      nzTitle="عدد مرات الفوز"
    />
    <span
      class="icon"
      nz-icon
      nzType="smile"
      nzTheme="fill"
      style="color: #5cad5b80"
    ></span>
  </nz-col>
  <nz-col [nzSpan]="8">
    <nz-statistic
      [nzValue]="(user.stats['numberOfFails'] | number)!"
      nzTitle="عدد مرات الخسارة"
    />
    <span
      class="icon"
      nz-icon
      nzType="frown"
      nzTheme="fill"
      style="color: #be4a4a7a"
    ></span>
  </nz-col>
</nz-row>
