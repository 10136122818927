import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-friend-dialog',
  templateUrl: './add-friend-dialog.component.html',
  styleUrls: ['./add-friend-dialog.component.css'],
})
export class AddFriendDialogComponent {
  email = new FormControl('', [Validators.required, Validators.email]);

  constructor(
    private dialogRef: MatDialogRef<AddFriendDialogComponent>,
    private userService: UserService
  ) {}

  getErrorMessageEmail() {
    if (this.email.hasError('email')) {
      return 'عنوان البريد الإلكتروني المدخل غير صحيح';
    } else return 'يجب تعبئة هذه الخانة';
  }

  addFriend() {
    // add friend by email
    this.userService.addFriend(this.email.value!);
    this.dialogRef.close('success');
  }
}
