<div class="page-container">
  <div class="leave-icon">
    <mat-icon
      nz-tooltip
      nzTooltipPlacement="top"
      nzTooltipTitle="مغادرة الدرس"
      [nzTooltipMouseEnterDelay]="0.5"
      (click)="leaveLesson()"
    >
      exit_to_app
    </mat-icon>
  </div>
  <div class="lesson-header">
    <span
      class="heart-icon"
      *ngFor="let heart of hearts"
      [ngClass]="{ 'gray-heart': !heart.keep }"
      nz-icon
      nzType="heart"
      nzTheme="fill"
    ></span>

    <nz-progress
      [nzPercent]="percent"
      [nzShowInfo]="false"
      nzStrokeWidth="15"
      nzStrokeColor="#6ba0c7"
    />
  </div>

  <span *ngIf="!endLesson">
    <h1 class="title">
      {{ isQuestion ? "قم بأداء" : "تعلم" }} إشارة حرف
      {{ lesson.content.at(contentIndex)?.title }}
    </h1>

    <div class="video-container">
      <!-- Learn the sign -->
      <iframe
        *ngIf="
          !isQuestion &&
          lesson.content.at(contentIndex) &&
          lessonCategory == 'alphabet'
        "
        width="480"
        height="270"
        [src]="getSafeUrl(lesson.content.at(contentIndex)?.media!)"
        title="YouTube video player"
        frameborder="0"
      ></iframe>

      <img
        *ngIf="lessonCategory == 'numbers'"
        [src]="lesson.content.at(contentIndex)?.media!"
      />

      <!-- Sign detected spinner -->
      <div class="loading detector" *ngIf="isQuestion">
        <nz-progress
          nzType="circle"
          [nzPercent]="(consecutiveClassCount / 3) * 100"
          [nzShowInfo]="false"
          nzStrokeWidth="10"
          nzStrokeColor="#6ba0c7"
        />

        <p>...الرجاء البقاء ثابتا حتى يكتمل تحديد الإشارة</p>
      </div>

      <!-- Do the sign -->
      <app-user-camera
        *ngIf="isQuestion"
        [lessonCategory]="lessonCategory"
        (receivedResponse)="ckeckAnswer($event)"
      ></app-user-camera>

      <!-- Loading contents -->
      <div class="loading" *ngIf="!lesson.content.at(contentIndex)">
        <mat-spinner />
        <p>...الرجاء الانتظار حتى ينتهي تحميل البيانات</p>
      </div>
    </div>
  </span>

  <div
    class="end-lesson"
    *ngIf="endLesson"
    [ngClass]="isPass() === 'succeeded' ? 'correct' : 'wrong'"
  >
    <h1 class="title">نهاية الدرس</h1>
    <p>
      {{
        isPass() === "succeeded"
          ? "مبارك، لقد أنهيت الدرس بنجاح"
          : "للأسف، لم تستطع إنهاء الدرس بنجاح، حاول مرة أخرى"
      }}
    </p>
  </div>

  <div
    class="lesson-footer"
    [ngClass]="{
      correct: showFeedback && isCorrect,
      wrong: showFeedback && !isCorrect
    }"
  >
    <!-- correct answer -->
    <span class="feedback" *ngIf="showFeedback && isCorrect">
      <p>إجابة صحيحة</p>
      <img
        src="../../../../assets/images/lesson-quiz-page/correct.png"
        width="80px"
      />
    </span>

    <!-- wrong answer -->
    <span class="feedback" *ngIf="showFeedback && !isCorrect">
      <img
        src="../../../../assets/images/lesson-quiz-page/losingHeart.png"
        width="80px"
      />
      <p>إجابة خاطئة</p>
      <img
        src="../../../../assets/images/lesson-quiz-page/wrong.png"
        width="80px"
      />
    </span>

    <span class="footer-buttons">
      <button
        mat-raised-button
        *ngIf="!endLesson"
        [disabled]="
          (isQuestion && !isCorrect) || !lesson.content.at(contentIndex)
        "
        (click)="next()"
      >
        التالي
      </button>

      <button mat-raised-button *ngIf="endLesson" (click)="leaveLesson()">
        إنهاء الدرس
      </button>
    </span>
  </div>
</div>
