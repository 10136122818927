import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { ApiService } from '../services/api.service';

export const authGuard: CanActivateFn = (route, state) => {
  const apiService = inject(ApiService);

  if (apiService.isLoggedIn()) {
    return true;
  } else {
    const router = inject(Router);
    return router.navigate(['login']);
  }
};
