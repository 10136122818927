<div class="page-container">
  <form [formGroup]="loginForm" (ngSubmit)="login()" #formDirective="ngForm">
    <mat-card class="login-reg">
      <mat-card-header>
        <mat-card-title> تسجيل الدخول </mat-card-title>
      </mat-card-header>

      <mat-card-content>
        <mat-divider style="margin-bottom: 1rem" />

        <mat-form-field>
          <mat-label>أدخل عنوان البريد الإلكتروني</mat-label>
          <mat-icon matPrefix>email</mat-icon>
          <input
            matInput
            placeholder="example@example.com"
            [formControl]="email"
            required
          />
          <mat-error *ngIf="email.invalid">{{
            getErrorMessageEmail()
          }}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>أدخل كلمة المرور</mat-label>
          <mat-icon matPrefix>lock</mat-icon>
          <input
            matInput
            required
            [formControl]="password"
            [type]="hide ? 'password' : 'text'"
          />
          <button
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
          >
            <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
          </button>
          <mat-error *ngIf="password.invalid">{{
            getErrorMessage()
          }}</mat-error>
        </mat-form-field>
      </mat-card-content>

      <mat-card-actions>
        <button mat-raised-button [disabled]="loginForm.invalid">
          تسجيل الدخول
        </button>
      </mat-card-actions>
      <div class="footer-action">
        ليس لديك حساب؟
        <a routerLink="/register"> إنشاء حساب جديد</a>
      </div>
    </mat-card>
  </form>
</div>
