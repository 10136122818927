import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { UserService } from './services/user.service';
import { ApiService } from './services/api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LessonsService } from './services/lessons.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'EmaaProject';
  isAppPage = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private apiService: ApiService,
    private lessonsService: LessonsService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.isAppPage = e.url != '/homePage' && e.url != '/';
      }
    });

    // if the user is logged in, fetch their info after reload page
    if (this.apiService.isLoggedIn()) {
      try {
        // update all user info, await until it's complete
        this.userService.fetchAfterReload();
      } catch (error) {
        console.error('Error fetching user info after reload page:', error);
      }
    }
  }

  navigateToLessonPage() {
    this.router.navigate(['/lessons-list']);
  }
}
