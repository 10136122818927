import { Component } from '@angular/core';
import { UserInfo } from 'src/app/interfaces/user';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent {
  pages = [
    { title: 'الدروس', path: '/lessons-list' },
    { title: 'المعجم', path: '/dictionary' },
    { title: 'المتصدرون', path: '/leaderboard' },
    { title: 'تواصل معنا', path: '/aboutus' },
  ];

  userInfo: UserInfo = this.userService.userInfo;

  constructor(
    private apiService: ApiService,
    private userService: UserService
  ) {}

  isLoggedin() {
    return this.apiService.isLoggedIn();
  }

  logout() {
    this.apiService.logout();
  }
}
