import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AchievementsList } from '../interfaces/user';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private baseUrl = 'https://emaa.work.gd/api';

  // header with the authToken
  private headers = new HttpHeaders({
    'ngrok-skip-browser-warning': 'true',
    Authorization: this.getAuthToken(),
  });

  // set authentication token and update headers
  setAuthToken(authToken: string, id: string, email: string) {
    localStorage.setItem('authToken', authToken);
    this.updateHeader();

    // set user id and email
    localStorage.setItem('id', id);
    localStorage.setItem('email', email);
  }

  // get authentication token from localStorage
  getAuthToken(): string {
    return localStorage.getItem('authToken') || '';
  }

  // remove authentication token from localStorage
  logout() {
    localStorage.removeItem('authToken');

    this.updateHeader(); // update headers after logout

    // clear user id and email
    localStorage.removeItem('id');
    localStorage.removeItem('email');
  }

  // check if user is logged in
  isLoggedIn(): boolean {
    return !!this.getAuthToken();
  }

  // update header with the user authToken
  updateHeader() {
    this.headers = this.headers.set('Authorization', this.getAuthToken());
  }

  constructor(private http: HttpClient) {}

  // login
  login(email: string, password: string): Observable<any> {
    const body = {
      email: email,
      password: password,
    };

    return this.http.post(`${this.baseUrl}/users/login`, body);
  }

  // registeration
  register(name: string, email: string, password: string) {
    const body = {
      name: name,
      email: email,
      password: password,
    };
    return this.http.post(`${this.baseUrl}/users/register`, body);
  }

  // verify new user
  verify(email: string, verificationToken: string) {
    const body = {
      email: email,
      verificationToken: verificationToken,
    };
    return this.http.post(`${this.baseUrl}/users/verify`, body);
  }

  // add friend
  addFriend(userId: string, email: string) {
    const body = {
      email: email,
    };

    return this.http.post(
      `${this.baseUrl}/users/user/${userId}/friends`,
      body,
      { headers: this.headers }
    );
  }

  // get friends
  getFriends(userId: string): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/users/user/${userId}/friends`, {
      headers: this.headers,
    });
  }

  // delete friend
  deleteFriend(userId: string, friendId: string) {
    return this.http.delete(
      `${this.baseUrl}/users/user/${userId}/friends/${friendId}`,
      { headers: this.headers }
    );
  }

  // update name
  updateName(newName: string) {
    const body = {
      newName: newName,
    };

    return this.http.put(`${this.baseUrl}/profiles/profile/updateName`, body, {
      headers: this.headers,
    });
  }

  // get profile
  getProfile() {
    return this.http.get<any>(`${this.baseUrl}/profiles/profile`, {
      headers: this.headers,
    });
  }

  // upload profile picture
  uploadProfilePicture(formData: FormData) {
    return this.http.post(`${this.baseUrl}/profiles/profile/upload`, formData, {
      headers: this.headers,
    });
  }

  // download profile picture
  downloadProfilePicture(userProfilePic: string) {
    return this.http.get(`${this.baseUrl}/files/${userProfilePic}`, {
      headers: this.headers,
      responseType: 'blob',
    });
  }

  // get lesson
  getLesson(lessonId: string) {
    return this.http.get<any>(`${this.baseUrl}/lessons/lesson/${lessonId}`, {
      headers: this.headers,
    });
  }

  // get lessons
  getLessons() {
    return this.http.get<any>(`${this.baseUrl}/lessons/`, {
      headers: this.headers,
    });
  }

  // submit answer
  submitAnswer(lessonId: string, contentId: string, answer: string) {
    const body = {
      answer: answer,
    };

    return this.http.post(
      `${this.baseUrl}/lessons/lesson/${lessonId}/${contentId}/check`,
      body,
      {
        headers: this.headers,
      }
    );
  }

  // submit lesson
  submitLesson(lessonId: string, result: string, failedAttemptsCount: number) {
    console.error('here is the submit lesson api!!!');

    const body = {
      result: result,
      failedAttemptsCount: failedAttemptsCount,
    };

    return this.http.post(
      `${this.baseUrl}/lessons/lesson/${lessonId}/submit`,
      body,
      {
        headers: this.headers,
      }
    );
  }

  // get points
  getPoints(userId: string) {
    return this.http.get<any>(`${this.baseUrl}/users/user/${userId}/points`, {
      headers: this.headers,
    });
  }

  // get achievements
  getAchievements() {
    return this.http.get<AchievementsList>(
      `${this.baseUrl}/profiles/profile/achievements`,
      { headers: this.headers }
    );
  }

  // get stats
  getStat() {
    return this.http.get<any>(`${this.baseUrl}/profiles/profile/stats`, {
      headers: this.headers,
    });
  }

  // get leaderboard
  getLeaderboard() {
    return this.http.get<any>(`${this.baseUrl}/profiles/profile/leaderboard`, {
      headers: this.headers,
    });
  }
}
