<div class="page-container">
  <div class="leave-icon">
    <mat-icon
      nz-tooltip
      nzTooltipPlacement="top"
      nzTooltipTitle="مغادرة الدرس"
      [nzTooltipMouseEnterDelay]="0.5"
      (click)="leaveLesson()"
    >
      exit_to_app
    </mat-icon>
  </div>
  <div class="lesson-header">
    <!-- * STEP 4 * -->
    <span
      nz-popover
      nzPopoverTitle="رابعا: فقد القلوب"
      nzPopoverContent="
      إذا قمت بالإجابة بشكل خاطئ ستخسر قلبًا،
      عليك أن تعيد المحاولة للتعلم من جديد
      "
      nzPopoverPlacement="bottom"
      [nzPopoverVisible]="steps === 4"
      [nzPopoverTrigger]="null"
      [nzPopoverOverlayStyle]="{ width: '310px', textAlign: 'right' }"
    >
      <!-- * STEP 5 * -->
      <span
        nz-popover
        nzPopoverTitle="خامسا: الخسارة"
        nzPopoverContent="إذا خسرت 3 قلوب، عليك أن تعيد الدرس من جديد"
        nzPopoverPlacement="bottom"
        [nzPopoverVisible]="steps === 5"
        [nzPopoverTrigger]="null"
        [nzPopoverOverlayStyle]="{ width: '310px', textAlign: 'right' }"
      >
        <!-- * STEP 6 * -->
        <span
          nz-popover
          nzPopoverTitle="سادسا: المكافآت"
          nzPopoverContent="
          إذا انهيت الدرس دون خسارة أي قلب ستحصل على 10 نقاط مقابل كل سؤال،
          لكن في حالة خسارة أي قلب ستحصل على 5 نقاط مقابل كل سؤال
                    "
          nzPopoverPlacement="bottom"
          [nzPopoverVisible]="steps === 6"
          [nzPopoverTrigger]="null"
          [nzPopoverOverlayStyle]="{ width: '310px', textAlign: 'right' }"
        >
          <!-- * STEP 7 * -->
          <span
            nz-popover
            nzPopoverTitle="سابعا: المكافآت"
            nzPopoverContent="
          إذا كانت هذه محاولتك الثانية في الدرس:
          إذا انهيت الدرس دون خسارة أي قلب ستحصل على نقطتين مقابل كل سؤال،
            لكن في حالة خسارة أي قلب ستحصل على نقطة واحد مقابل كل سؤال
           "
            nzPopoverPlacement="bottom"
            [nzPopoverVisible]="steps === 7"
            [nzPopoverTrigger]="null"
            [nzPopoverOverlayStyle]="{ width: '310px', textAlign: 'right' }"
          >
            <span
              class="heart-icon"
              *ngFor="let heart of hearts"
              [ngClass]="{ 'gray-heart': !heart.keep }"
              nz-icon
              nzType="heart"
              nzTheme="fill"
            ></span>
          </span>
        </span>
      </span>
    </span>

    <nz-progress
      [nzPercent]="percent"
      [nzShowInfo]="false"
      nzStrokeWidth="15"
      nzStrokeColor="#6ba0c7"
    />
  </div>

  <h1 *ngIf="steps != 8" class="title">
    {{ isQuestion ? "قم بأداء" : "تعلم" }} إشارة الحرف
  </h1>

  <div class="video-container">
    <!-- Learn the sign -->
    <!-- * STEP 1 * -->
    <span
      nz-popover
      nzPopoverTitle="أولا: بداية رحلتك"
      nzPopoverContent="هنا نعلمك لغة الإشارة العربية"
      nzPopoverPlacement="rightTop"
      [nzPopoverVisible]="steps === 1"
      [nzPopoverTrigger]="null"
      [nzPopoverOverlayStyle]="{
        marginLeft: '50px',
        textAlign: 'right'
      }"
    >
      <!-- Sign detected spinner -->
      <div class="loading detector" *ngIf="isQuestion && steps < 5">
        <nz-progress
          nzType="circle"
          [nzPercent]="isCorrect ? 100 : 0"
          [nzShowInfo]="false"
          nzStrokeWidth="10"
          nzStrokeColor="#6ba0c7"
        />

        <p>...الرجاء البقاء ثابتا حتى يكتمل تحديد الإشارة</p>
      </div>

      <img
        *ngIf="!isQuestion && steps != 8"
        src="assets\images\lesson-quiz-page\intro-learn-page-vid-example.png"
        alt="Display vidoe to learn"
      />
    </span>

    <!-- Do the sign -->
    <!-- * STEP 2 * -->
    <span
      nz-popover
      nzPopoverTitle="ثانيا: تعلم الحروف"
      nzPopoverContent="سنطلب منك أداء الإشارة التي تعلمتها"
      nzPopoverPlacement="rightTop"
      [nzPopoverVisible]="steps === 2"
      [nzPopoverTrigger]="null"
      [nzPopoverOverlayStyle]="{
        margiLeft: '50px',
        textAlign: 'right'
      }"
    >
      <!-- * STEP 8 * -->
      <span
        nz-popover
        nzPopoverTitle="أخيرا: تحقق من تقدمك"
        nzPopoverContent="يمكنك تتبع تقدمك ونقاطك في صفحتك الشخصية"
        nzPopoverPlacement="rightTop"
        [nzPopoverVisible]="steps === 8"
        [nzPopoverTrigger]="null"
        [nzPopoverOverlayStyle]="{
          margiLeft: '50px',
          textAlign: 'right'
        }"
      >
        <div
          *ngIf="isQuestion || steps === 8"
          class="userCamera"
          [ngStyle]="{ 'background-color': isQuestion ? 'black' : '' }"
        >
          <img
            *ngIf="!isQuestion"
            src="assets\images\lesson-quiz-page\show-stats.png"
            alt="Show stats"
          />
          <mat-icon *ngIf="isQuestion && steps !== 8">videocam</mat-icon>
        </div>
      </span>
    </span>
  </div>

  <!-- * STEP 3 * -->
  <div
    class="lesson-footer"
    [ngClass]="{
      correct: showFeedback && isCorrect,
      wrong: showFeedback && !isCorrect
    }"
    nz-popover
    nzPopoverTitle="ثالثا: الإجابة الصحيحة"
    nzPopoverContent="إذا قمت بالإجابة بشكل صحيح، ستنتقل إلى السؤال التالي"
    nzPopoverPlacement="topRight"
    [nzPopoverVisible]="steps === 3"
    [nzPopoverTrigger]="null"
    [nzPopoverOverlayStyle]="{ textAlign: 'right' }"
  >
    <!-- correct answer -->

    <span class="feedback" *ngIf="showFeedback && isCorrect">
      <p>إجابة صحيحة</p>
      <img
        src="../../../../assets/images/lesson-quiz-page/correct.png"
        width="80px"
      />
    </span>

    <!-- wrong answer -->
    <span class="feedback" *ngIf="showFeedback && !isCorrect">
      <img
        src="../../../../assets/images/lesson-quiz-page/losingHeart.png"
        width="80px"
      />
      <p>إجابة خاطئة</p>
      <img
        src="../../../../assets/images/lesson-quiz-page/wrong.png"
        width="80px"
      />
    </span>

    <span class="footer-buttons">
      <button
        *ngIf="percent < 100"
        mat-raised-button
        (click)="next()"
        nz-popover
        nzPopoverContent='.انقر على زر "التالي" للمتابعة'
        nzPopoverPlacement="top"
        [nzPopoverVisible]="true"
        [nzPopoverTrigger]="null"
        [nzPopoverOverlayStyle]="{ textAlign: 'right' }"
      >
        التالي
      </button>

      <button *ngIf="percent > 100" mat-raised-button (click)="leaveLesson()">
        إنهاء التمهيد
      </button>

      <button mat-raised-button *ngIf="percent > 0" (click)="prev()">
        السابق
      </button>
    </span>
  </div>
</div>
