import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  socket?: WebSocket;

  constructor() {}

  connect(url: string) {
    if (this.socket) {
      this.socket.close();
    }

    this.socket = new WebSocket(url);

    this.socket.onclose = () => {
      console.log('WebSocket Disconnected on close');
      // Perform additional cleanup if needed
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket Error:', error);
      // Handle errors as appropriate
    };
  }
}
