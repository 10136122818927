import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { LessonsService } from 'src/app/services/lessons.service';
import { lessonData } from 'src/app/interfaces/lessons';
import { UserCameraComponent } from './user-camera/user-camera.component';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-lesson-page',
  templateUrl: './learn-page.component.html',
  styleUrls: ['./learn-page.component.css'],
})
export class LearnPageComponent implements OnInit {
  @ViewChild(UserCameraComponent) userCameraComponent!: UserCameraComponent;

  contentIndex = 0;
  hearts = [{ keep: true }, { keep: true }, { keep: true }];
  heartCount = 0;

  percent = 0;
  isQuestion: boolean = false;
  isCorrect = false;
  showFeedback = false;
  endLesson: boolean = false;
  lessonCategory = '';

  consecutiveClassCount: number = 0;
  private resetCounter: number = 0;
  private lastReceivedClass: string = '';

  // footer

  lesson: lessonData = {
    id: '',
    lessonTitle: '',
    category: '',
    type: '',
    description: '',
    order: 0,
    result: false,
    content: [],
  };

  lessonLength = 0;

  constructor(
    private sanitizer: DomSanitizer,
    private lessonService: LessonsService,
    private route: ActivatedRoute,
    private titleService: Title,
    private router: Router,
    private notification: NzNotificationService
  ) {}

  //vid info, use getLesson api
  async ngOnInit() {
    // update lesson list data
    await this.lessonService.getLessons();

    // get lesson data
    this.route.params.subscribe((params) => {
      this.lesson.id = params['id'];
      this.lesson.category = params['category'];
    });

    // get lesson
    await this.getLesson();

    // set page title
    await this.setPageTitle();

    // set lesson length
    this.lessonLength = this.lesson.content.length;

    this.isQuestion = this.lesson.content[0].type === 'question';
  }

  // get lesson by id
  async getLesson() {
    if (this.lesson.category === 'alphabet') {
      this.lessonCategory = 'alphabet';
      this.lesson =
        this.lessonService.lessonList.alphabet.find(
          (lesson) => lesson.id === this.lesson.id
        ) || this.lesson;
    } else {
      this.lessonCategory = 'numbers';
      this.lesson =
        this.lessonService.lessonList.numbers.find(
          (lesson) => lesson.id === this.lesson.id
        ) || this.lesson;
    }
  }

  // set page title
  async setPageTitle() {
    this.titleService.setTitle(this.lesson?.lessonTitle || 'درس');
  }

  // display vid
  getSafeUrl(url: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(
      `https://www.youtube.com/embed/${url}`
    );
  }

  next() {
    if (this.percent >= 100) {
      this.endLesson = true;
      this.isQuestion = false;
      this.showFeedback = false;
      this.submitLesson();
    } else {
      this.showFeedback = false;
      this.isCorrect = false;
      this.increaseProgressBar();
    }
  }

  increaseProgressBar(): void {
    // update content index while it is not the end of the lesson
    this.contentIndex++;

    // update the content type
    if (this.contentIndex < this.lessonLength) {
      this.isQuestion =
        this.lesson.content[this.contentIndex].type === 'question';

      // if question, create user camera
      if (this.isQuestion && this.userCameraComponent) {
        this.userCameraComponent.startRecognition();
      }
    }

    // update the progress bar percent
    this.percent =
      this.percent > 100
        ? 100
        : ((this.contentIndex + 1) / this.lessonLength) * 100;
  }

  // submit answers

  ckeckAnswer(receivedResponse: { class: string; confidence: number }) {
    console.log(receivedResponse);

    // check if the received class is null
    if (receivedResponse.class === null) {
      this.consecutiveClassCount = 0;
      this.resetCounter++;
      this.resetNotif();
    } else if (receivedResponse.confidence < 0.8) {
      // do nothing
    }

    // check if the received class is the same as the last one
    else if (receivedResponse.class === this.lastReceivedClass) {
      console.log('received as prev: ', receivedResponse);

      // increment the consecutive class count
      this.consecutiveClassCount++;
    } else {
      console.log('NOT as prev: ', receivedResponse);

      // reset the consecutive class count if the received class is different
      this.consecutiveClassCount = 0;
      this.resetCounter++;
      this.resetNotif();
      this.lastReceivedClass = receivedResponse.class;
    }

    // if the same class has been received three times consecutively
    if (this.consecutiveClassCount === 3) {
      // resert the resetCounter
      this.resetCounter = 0;

      this.showFeedback = false;

      // submit the answer to the service
      this.submit(receivedResponse.class);
    }
  }

  resetNotif() {
    // notif user every 5 reset
    if (this.resetCounter !== 0 && this.resetCounter % 5 === 0) {
      this.notification.create(
        'warning',
        'انتباه',
        'تأكد من أن تكون يدك واضحة وثابتة حتى يتم إرسال جوابك',
        {
          nzDuration: 3000,
        }
      );
    }
  }

  async submit(classReceived: string) {
    console.log('################ SUBMIT ################', classReceived);

    const lessonId = this.lesson.id;
    const contentId = this.lesson.content[this.contentIndex].id;

    try {
      // Call the submitAnswer method and wait for the response
      this.isCorrect = await this.lessonService.submitAnswer(
        lessonId,
        contentId,
        classReceived,
        this.heartCount
      );

      // Show feedback
      this.showFeedback = true;

      // close socket
      if (this.isCorrect && this.userCameraComponent) {
        this.userCameraComponent.close();
        this.consecutiveClassCount = 0;
      }
      // Handle incorrect answer if applicable
      if (!this.isCorrect) {
        this.consecutiveClassCount = 0;
        this.handleIncorrectAnswer();
      }
    } catch (error) {
      // Handle error if needed
      console.error(error);
    }
  }

  // lose one heart
  handleIncorrectAnswer() {
    if (this.heartCount < 2) {
      this.hearts[this.heartCount++].keep = false;
    } else {
      this.hearts[this.heartCount++].keep = false;
      this.endLesson = true;
      this.isQuestion = false;
      this.showFeedback = false;
      this.percent = 100;
      this.submitLesson();
    }
  }

  // submit lesson
  submitLesson() {
    console.log('~~~~~~~~~~~~~~~~~~~~~~ SUBMIT LESSON ~~~~~~~~~~~~~~~~~~~~~~');

    const result = this.isPass();
    this.lessonService.submitLesson(this.lesson.id, result, this.heartCount);
  }

  isPass(): string {
    return this.heartCount == 3 ? 'failed' : 'succeeded';
  }

  leaveLesson() {
    this.router.navigate(['/lessons-list']);
  }
}
