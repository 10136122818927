import { Component } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { UserInfo } from 'src/app/interfaces/user';
import { HttpClient } from '@angular/common/http';

interface AchievementCardInfo {
  achievement: string;
  info: { name: string; description: string; imgSrc: string };
}

@Component({
  selector: 'app-user-achievements',
  templateUrl: './user-achievements.component.html',
  styleUrls: ['./user-achievements.component.css'],
})
export class UserAchievementsComponent {
  user: UserInfo = this.userService.userInfo;
  achievementCardsInfo: AchievementCardInfo[] = [];

  constructor(private userService: UserService, private http: HttpClient) {}

  ngOnInit(): void {
    this.http
      .get('assets/local-data/achievements-data.json')
      .subscribe((data: any) => {
        this.achievementCardsInfo = data;
      });
  }

  baseImgSrc = '../../../../assets/images/achievement-icons/';

  getImgSrc(imgSrc: string) {
    return this.baseImgSrc + imgSrc;
  }

  getAchievementInfo(
    achievement: string
  ): { name: string; description: string; imgSrc: string } | null {
    const cardInfo = this.achievementCardsInfo.find(
      (info) => info.achievement === achievement
    );
    return cardInfo ? cardInfo.info : null;
  }
}
