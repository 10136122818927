import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dictionary-card',
  templateUrl: 'dictionary-card.component.html',
  styleUrls: ['dictionary-card.component.css'],
})
export class DictionaryCardComponent implements OnInit {
  @Input({ required: true })
  rawDictionaryCards: any;

  @Input({ required: true })
  type: string = '';

  dictionaryCards: Array<any> = [];

  isEmpty: boolean = false;

  ngOnInit() {
    this.formatDictionaryCards();
    this.isEmpty = this.dictionaryCards.length == 0;
  }

  formatDictionaryCards() {
    if (this.type === 'words') {
      // Transform the object into an array of objects each containing a key and value (the array of cards)
      this.dictionaryCards = Object.entries(this.rawDictionaryCards).map(
        ([key, value]) => ({ key, value })
      );
    } else {
      // Use the array as it is
      this.dictionaryCards = this.rawDictionaryCards;
    }
  }

  baseImgSrc = '../../../../assets/images/dictionary-imgs/';
  baseVidSrc = '../../../../assets/videos/dictionary-vids/';

  getMediaSrc(src: string) {
    return this.type === 'words'
      ? this.baseVidSrc + src
      : this.baseImgSrc + src;
  }
}
