<span id="dictionary-cards">
  <!-- Show when no cards are available -->
  <p class="no-cards" *ngIf="isEmpty">سيتم إضافة المزيد قريبا</p>

  <!-- Handling "alphabet" and "numbers" types -->
  <ng-container *ngIf="type !== 'words'">
    <mat-card *ngFor="let card of dictionaryCards">
      <img nz-image [alt]="card.name" [nzSrc]="getMediaSrc(card.src)" />
      <mat-card-header>
        <mat-card-title>{{ card.name }}</mat-card-title>
        <mat-card-title *ngIf="type !== 'words' && card.letter">
          "{{
            card.letter == "1,000,000,000"
              ? card.letter
              : " " + card.letter + " "
          }}"
        </mat-card-title>
      </mat-card-header>
    </mat-card>
  </ng-container>

  <!-- Handling "words" type -->
  <ng-container *ngIf="type === 'words'">
    <mat-tab-group dynamicHeight mat-stretch-tabs="false" [dir]="'rtl'">
      <mat-tab *ngFor="let category of dictionaryCards" [label]="category.key">
        <mat-card class="words" *ngFor="let card of category.value">
          <video
            width="320"
            height="240"
            controls
            [src]="getMediaSrc(card.src)"
            type="video/mp4"
          ></video>
          <mat-card-header>
            <mat-card-title>{{ card.name }}</mat-card-title>
          </mat-card-header>
        </mat-card>
      </mat-tab>
    </mat-tab-group>
  </ng-container>

  <!-- 
<ng-container *ngIf="type === 'words'">
  <ng-container *ngFor="let category of dictionaryCards">
    <div class="subTitle">{{ category.key }}</div>

    <mat-card class="words" *ngFor="let card of category.value">
      <video
        width="320"
        height="240"
        controls
        [src]="getMediaSrc(card.src)"
        type="video/mp4"
      ></video>
      <mat-card-header>
        <mat-card-title>{{ card.name }}</mat-card-title>
      </mat-card-header>
    </mat-card>
  </ng-container>
</ng-container>
 -->
</span>
