<div class="page-container">
  <div class="pageTitle">
    <h1>الدروس</h1>
  </div>

  <mat-sidenav-container>
    <mat-sidenav mode="side" opened position="end">
      <span class="buttonList">
        <button
          *ngFor="let title of titles; let i = index"
          mat-raised-button
          [class.inactive]="selectedLessonType !== i"
          (click)="selectLessonType(i)"
        >
          {{ title }}
        </button>
      </span>
    </mat-sidenav>

    <mat-sidenav-content>
      <app-lessons-list
        [@listAnimation]="changeCounter"
        [lessonsData]="tiles"
        [title]="title"
      />
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
