import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css'],
})
export class LoginPageComponent {
  hide = true;

  loginForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private router: Router,
    private notification: NzNotificationService,
    private userService: UserService
  ) {}

  get email() {
    return this.loginForm.controls['email'];
  }
  get password() {
    return this.loginForm.controls['password'];
  }

  getErrorMessageEmail() {
    if (this.email.hasError('email')) {
      return 'عنوان البريد الإلكتروني المدخل غير صحيح';
    } else return this.getErrorMessage();
  }

  getErrorMessage() {
    return 'يجب تعبئة هذه الخانة';
  }

  login() {
    const email = this.email.value!;
    const password = this.password.value!;

    this.apiService.login(email as string, password as string).subscribe(
      (response) => {
        if (response.authorization) {
          const authorization = response.authorization;
          const userId = response.user.id;
          const email = response.user.email;

          // save user authToken
          this.apiService.setAuthToken(authorization, userId, email);

          // call a service to update user info
          this.userService.fetchUserInfo(userId, email);

          // redirect to homepage
          this.router.navigate(['/homePage']);
        }
      },
      (error) => {
        if (
          error.error.error === 'Invalid password or email' ||
          error.error.error === 'User not found'
        ) {
          this.notification.create(
            'error',
            'خطأ',
            'كلمة المرور أو عنوان البريد الإلكتروني غير صحيح',
            {
              nzDuration: 3000,
            }
          );
        } else if (error.error.error === 'Unverified user') {
          this.notification.create(
            'error',
            'خطأ',
            'يجب تأكيد الحساب أولا، تم إرسال رابط تأكيد الحساب إلى عنوان البريد الإلكتروني',
            {
              nzDuration: 3000,
            }
          );
        } else {
          this.notification.create('error', 'خطأ', 'عفوا، حدث خطأ غير متوقع', {
            nzDuration: 3000,
          });
        }
      }
    );
  }
}
