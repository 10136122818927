<mat-hint>
  هذه الدروس تستعمل الكاميرا حتى تتعرف على إشارتك بشكل تلقائي
  <mat-icon>videocam</mat-icon>
</mat-hint>
<h1>دروس {{ title }}</h1>

<p *ngIf="isEmpty" class="no-lessons">سيتم إتاحة الدروس قريبا</p>

<div class="loading" *ngIf="!isEmpty && tiles.length == 3">
  <mat-spinner />
  <p>...الرجاء الانتظار حتى ينتهي تحميل البيانات</p>
</div>

<mat-grid-list *ngIf="!isEmpty && tiles.length > 3" cols="4" rowHeight="70px">
  <mat-grid-tile
    *ngFor="let tile of tiles"
    [colspan]="tile.cols"
    [rowspan]="tile.rows"
  >
    <mat-icon
      *ngIf="isLesson(tile) && tile.lessonSide"
      nz-tooltip
      nzTooltipPlacement="left"
      [nzTooltipTitle]="tile.description"
      [nzTooltipMouseEnterDelay]="0.5"
    >
      info_outline
    </mat-icon>

    <a
      *ngIf="isLesson(tile)"
      [routerLink]="getPagePath(tile.category!, tile.id!)"
      [ngClass]="{
        'unavailable-lesson': !tile.available,
        'done-lesson': tile.result && tile.name !== 'start'
      }"
    >
      <img
        [ngClass]="isLesson(tile) ? 'lesson' : 'rock'"
        [src]="getImagePath(tile.name)"
        alt="{{ tile.name }}"
      />
    </a>

    <mat-icon
      *ngIf="isLesson(tile) && !tile.lessonSide"
      nz-tooltip
      nzTooltipPlacement="right"
      [nzTooltipTitle]="tile.description"
      [nzTooltipMouseEnterDelay]="0.5"
    >
      info_outline
    </mat-icon>

    <img
      *ngIf="notFiller(tile)"
      [ngClass]="isLesson(tile) ? 'lesson' : 'rock'"
      [src]="getImagePath(tile.name)"
      alt="{{ tile.name }}"
    />
  </mat-grid-tile>
</mat-grid-list>
