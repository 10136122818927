import { Injectable } from '@angular/core';
import { Content, LessonList, lessonData } from '../interfaces/lessons';
import { ApiService } from './api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { HttpClient } from '@angular/common/http';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class LessonsService {
  lessonList: LessonList = {
    alphabet: [],
    numbers: [],
  };

  lessonTitles: string[] = [];

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private notification: NzNotificationService,
    private http: HttpClient
  ) {}

  // fetch lesson list
  async getLessons(): Promise<void> {
    try {
      const lessonTitles = await this.http
        .get<string[]>('assets/local-data/lesson-titles-data.json')
        .toPromise();
      this.lessonTitles = lessonTitles || [];

      let response = await this.apiService.getLessons().toPromise();

      if (response.alphabet) {
        // sort the lessons based on the order
        response.alphabet.sort(
          (a: lessonData, b: lessonData) => a.order - b.order
        );

        await Promise.all(
          response.alphabet.map(async (lesson: lessonData) => {
            lesson.content = (await this.getLesson(lesson.id)) || [];
            lesson.description =
              lesson.type === 'standard'
                ? this.getLessonDescription(lesson.category, lesson.content)
                : 'يحتوي هذا الدرس على أسئلة مراجعة للدروس السابقة';
            lesson.lessonTitle = this.lessonTitles[lesson.order] || '';
          })
        );
      }

      if (response.numbers) {
        // sort the lessons based on the order
        response.numbers.sort(
          (a: lessonData, b: lessonData) => a.order - b.order
        );

        await Promise.all(
          response.numbers.map(async (lesson: lessonData) => {
            lesson.content = (await this.getLesson(lesson.id)) || [];
            lesson.content.forEach((content) => {
              if (content.type === 'information') {
                const mediaUrl = `../assets/images/dictionary-imgs/numbers/${Math.ceil(
                  content.order / 2
                )}.png`;

                content.media = mediaUrl;
              }
            });
            lesson.description =
              lesson.type === 'standard'
                ? this.getLessonDescription(lesson.category, lesson.content)
                : 'يحتوي هذا الدرس على أسئلة مراجعة للدروس السابقة';
            lesson.lessonTitle = this.lessonTitles[lesson.order] || '';
          })
        );
      }

      this.updateLessonList(response);
    } catch (error) {
      console.error('Get lesson error: ', error);

      this.notification.create(
        'error',
        'خطأ',
        'عفوا، حدث خطأ غير متوقع، لم نتمكن من إظهار قائمة الدروس',
        {
          nzDuration: 3000,
        }
      );
    }
  }

  // fetch lesson
  async getLesson(lessonId: string): Promise<Content[] | void> {
    try {
      const response = await this.apiService.getLesson(lessonId).toPromise();

      return response.content;
    } catch (error) {
      console.error(`Get lesson ${lessonId} error: `, error);
    }
  }

  // update lesson list
  updateLessonList(lessonList: LessonList) {
    this.lessonList = lessonList;
  }

  // generate a lesson description
  getLessonDescription(category: string, content: Content[]): string {
    // filter content items where type is 'information'
    const informationItems = content.filter(
      (item) => item.type === 'information'
    );

    // extract titles from the filtered items
    const titles = informationItems.map((item) => item.title);

    // remove duplicates
    const uniqueTitles = Array.from(new Set(titles));

    let translatedCategory = '';
    switch (category) {
      case 'alphabet':
        translatedCategory = 'الحروف';
        break;
      case 'numbers':
        translatedCategory = 'الأرقام';
        break;
      case 'words':
        translatedCategory = 'الكلمات';
        break;
    }
    // format the description string
    const description = `يحتوي هذا الدرس على ${translatedCategory} التالية: ${uniqueTitles.join(
      '، '
    )}`;

    return description;
  }

  // submit answer
  async submitAnswer(
    lessonId: string,
    contentId: string,
    answer: string,
    heartCount: number
  ): Promise<boolean> {
    try {
      const response: any = await this.apiService
        .submitAnswer(lessonId, contentId, answer)
        .toPromise();

      if (!response.isCorrect && heartCount < 2) {
        this.notification.create('error', 'إجابة خاطئة', 'حاول من جديد', {
          nzDuration: 3000,
        });
      }
      return response.isCorrect;
    } catch (error) {
      console.error(error);
      return false; // Return false in case of an error
    }
  }

  // submit lesson
  submitLesson(lessonId: string, result: string, failedAttemptsCount: number) {
    this.apiService
      .submitLesson(lessonId, result, failedAttemptsCount)
      .subscribe(
        (response) => {
          this.userService.updateUserInfoAfterSubmit();

          if (result === 'succeeded') {
            this.notification.create(
              'success',
              'نجاح',
              'لقد انهيت الدرس بنجاح',
              {
                nzDuration: 5000,
              }
            );
          } else {
            this.notification.create(
              'error',
              'خسارة',
              'للاسف، لم تتمكن من إنهاء الدرس بنجاح، لكن لا تيأس وحاول من جديد',
              {
                nzDuration: 5000,
              }
            );
          }
        },
        (error) => {
          console.error(error);
          this.notification.create(
            'error',
            'خطأ',
            'عفوا، حدث خطأ غير متوقع، لم نتمكن من إرسال نتائج الدرس',
            {
              nzDuration: 3000,
            }
          );
        }
      );
  }
}
